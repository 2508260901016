import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'            //引入ElementUI
import 'element-ui/lib/theme-chalk/index.css' //导入样式
Vue.use(ElementUI)                            //使用
Vue.config.productionTip = false
import axios from 'axios'                     //引入axios
Vue.prototype.$axios = axios                   // 挂载到原型上，可以全局使用
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
