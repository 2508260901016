<template>
<div class="pageOne">
    <h3>网上快捷申请</h3>
    <div class="subTitle">
        <img src="../assets/icon_msg.png" alt="">
        <div>加<span>*</span>为必填项。</div>
    </div>
    <div class="form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="145px" class="formRule">
            <el-form-item label="商标类别" prop="category">
                
                <el-input v-model="ruleForm.category" type="number" placeholder="输入1-45之间的阿拉伯数字，例如查询第9类，请输入：9"></el-input>
            </el-form-item>
            <el-form-item label="商标类型" prop="type">
                <div class="xing">*</div>
                <el-radio-group v-model="ruleForm.type">
                    <el-radio label="中文"></el-radio>
                    <el-radio label="英文"></el-radio>
                    <el-radio label="数字"></el-radio>
                    <el-radio label="图形"></el-radio>
                    <el-radio label="中文+英文"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="筛选条件设置" prop="activity" class="setting">
                <div class="activityContent">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="margin-bottom:0.8rem;"></div>
                    <el-checkbox-group  v-model="ruleForm.activity" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="item in list" :label="item" :key="item">{{item}}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <el-form-item label="商标名称" prop="name">
                <div class="xing">*</div>
                <el-input v-model="ruleForm.name" placeholder="请输入待查询商标名称，例如：华为"></el-input>
            </el-form-item>

            
        </el-form>
        <div class="btnGroup">
            <button class="reset" @click="resetHandle">重置条件</button>
            <button class="searchBtn" @click="submitForm">立即查询></button>
           
        </div>
       <div class="mobile">电话咨询：<a href="tel:15701265930">15701265930</a></div>
        <div class="info">
            2024年的商标数据存在部分延迟，可去人工窗口免费二次核实
        </div>
    </div>
    <el-dialog title="网上快捷申请" :close-on-click-modal="false" :visible.sync="dialogVisible" width="554px" @close="closeHandle">
        <div class="dialogInfo">为保证信息安全，请填写手机号码并验证。查询结果稍后将发送至您的手机。</div>
        <div class="dialogform">
            <el-input placeholder="请输入商标名称" v-model="dialogForm.name">
                <template slot="prepend">商标名称</template>
            </el-input>
            <el-input placeholder="请输入手机号接收数据更新通知" style="margin:2rem 0" v-model="dialogForm.phone">
                <template slot="prepend">联系方式</template>
            </el-input>
            <div class="code">
                <el-input v-model="dialogForm.code" placeholder="请输入手机验证码"></el-input>
                <button class="codeBtn" :disabled="disabledStatus" @click="getCode">{{disabledStatus?`${timeNum}s后重新获取`:'获取验证码'}}</button>
            </div>
        </div>
        <div class="submitBtn" @click="submitSearchHandle">提交查询</div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
    </el-dialog>
</div>
</template>
<script>
export default{
    data(){
        return{
            dialogVisible:false,
            isIndeterminate: true,
            list:[
                '完全相同','加字符','含在其他商标中',
                '部分相同','减字符','内含其它商标',
                '字符改变','顺序颠倒','读音相同',
            ],
            checkAll: false,
            ruleForm: {
                category:null,
                type:'中文',
                activity:[],
                name: '',
            },
            rules: {
                type: [
                    { required: true, message: '请选择商标类型', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '请输入商标名称', trigger: 'blur' },
                   
                ],
            },
            dialogForm:{
                name:'',
                phone:'',
                code:'',
            },
            disabledStatus:false,
            timeNum:120,
            timer:null,
        }
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
    methods:{
        closeHandle(){
            clearInterval(this.timer);
        },
        handleCheckAllChange(val) {
            this.ruleForm.activity = val ? this.list : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.list.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;
        },
        submitForm(formName) {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.dialogForm = this.$options.data().dialogForm;
                    this.dialogForm.name = this.ruleForm.name;
                    this.dialogVisible = true;

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetHandle(formName) {
            this.$refs['ruleForm'].resetFields();
        },
        getCode(){
            
            clearInterval(this.timer);
            
            let reg = /^1[3-9]\d{9}$/;
            if(!reg.test(this.dialogForm.phone)){
                alert("请输入正确的联系方式");
                return;
            }
            this.$axios({
                method: "post",
                url: "https://bdjhy.jzynkj.com/api/sms/sendCode",
                data: {
                    mobile:this.dialogForm.phone
                }
            })
            .then((res)=>{
               console.log(res)
               if(res.data.code==1){
                    this.disabledStatus = true;
                    this.timeNum = 120;
                    this.timer = setInterval(()=>{
                        this.timeNum--
                    },1000)
                    if(this.timeNum==0){
                        clearInterval(this.timer);
                        this.disabledStatus = false;
                    }
               }else{
                    alert(res.data.msg)
                    this.disabledStatus = false;
                    clearInterval(this.timer);
               }
              
            })
            .catch((err)=>{
                console.log(err,"0000")
                clearInterval(this.timer);
                this.disabledStatus = false;
            })

            
        },
        submitSearchHandle(){
            if(!this.dialogForm.name){
                alert("请输入商标名称");
                return;
            }
            if(!this.dialogForm.phone){
                alert("请输入联系方式");
                return;
            }
            let reg = /^1[3-9]\d{9}$/;
            if(!reg.test(this.dialogForm.phone)){
                alert("请输入正确的联系方式");
                return;
            }
            if(!this.dialogForm.code){
                alert("请输入验证码");
                return;
            }
            this.$axios({
                method: "post",
                url: "https://bdjhy.jzynkj.com/api/trade/addTrade",
                data: {
                    mobile:this.dialogForm.phone,
                    captcha:this.dialogForm.code,
                    name:this.dialogForm.name,
                }
            })
            .then((res)=>{
               console.log(res)
               window._agl && window._agl.push(['track', ['success', {t: 3}]])
               if(res.data.code==1){
                    this.dialogVisible = false;
                    alert(res.data.msg);
               }else{
                alert(res.data.msg);
               }
            })
            .catch(function(err){
                
            })
            this.$axios({
                method: "post",
                url: "http://jhy.ofwl.cn/etc/api",
                data: {
                    title:this.dialogForm.name,
                    phone:this.dialogForm.phone,
                    timestamp:Math.floor(Date.now() / 1000),
                    ptype:1,
                    froto:'baidu',
                    term:'pc'
                }
            })
            .then((res)=>{
               console.log(res)
              
            })
            .catch(function(err){
                
            })
          
        },
    },
    watch:{
        timeNum(val){
            if(this.timeNum==0){
                clearInterval(this.timer);
                this.disabledStatus = false;
            }
        }
    }
}
</script>
<style  scoped>
.mobile{
    margin-top:2rem;
    font-size:1.4rem;
    margin-top: 8.7rem;
    margin-bottom:1rem;
    font-size: 2.0rem;
    font-weight:bold;
}
.pageOne{
   
    background-color: #fff;
    padding:4.5rem 0 0 0;
    margin-top:2rem;
}
h3{
    font-size: 3.0rem;
    color: #37496e;
    font-weight: 700;
    text-transform: uppercase;
    margin:0;
    padding:0;
}
.logo{
   display: flex;
   align-items: center;
}
.subTitle{
    font-size: 1.6rem;
    color: #d57d00;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    margin: 37px auto 0;
}
.subTitle img{
    margin-right: 12px;
}
.subTitle span{
    font-size: 1.8rem;
    color: #ff0000;
}
.form{
   
    padding-top: 6.2rem;
}
.form .formRule{
    
    width:80%;
    margin:0 auto;
}
>>>.el-form-item{
    position: relative;
}
/* >>>.el-form-item__content{
    text-align: left;
    margin-left: 166px !important;
    line-height: 2.5rem;
} */
>>>.el-form-item__label{
    font-size: 1.8rem;
    color: #FFFFFF;
    background-color: #2071b5;
    
    height: 5rem;
    text-align: center;
    border-radius: 8px;
    line-height: 5rem;
    
    text-align: center;
    padding: 0;
}
>>>.el-input__inner{
    height:5rem;
    background-color: #ececec;
    border: solid 1px #aaaaaa;
    color:#000;
    font-size:1.6rem;
}
>>>.is-checked .el-checkbox__label{
    color:#c40001 !important;
}
>>>.el-checkbox__input.is-checked .el-checkbox__inner, >>>.el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #c40001 !important;
    border-color: #c40001 !important;
}
>>>.el-radio__input.is-checked .el-radio__inner{
    background-color: #c40001 !important;
    border-color: #c40001 !important;
}
>>>.el-radio-group{
    line-height: 5rem;
}
>>>.el-radio__label,>>>.el-checkbox__label{
    color:#000;
    font-size:1.6rem;
}
>>>.el-radio__input.is-checked+.el-radio__label{
    color:#c40001;
}
>>>.el-checkbox{
    width:33%;
    margin:0;
}

>>>.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    /* content: '*';
    color: #F56C6C;
    margin-right: 4px; */
    display: none;
}
.activityContent{
    padding: 1.8rem;
    border: solid 1px #aaaaaa;
    
    border-radius: 8px;
}
.btnGroup{
   
    text-align: center;
    margin-top: 6rem;
}
.reset{
    text-align: center;
    cursor: pointer;
    margin: 0 9px;
    width: 16rem;
    height: 5rem;
    font-size: 1.8rem;
    color: #333333;
    background-color: #f5f5f5;
    cursor: pointer;
    border: solid 1px #aaaaaa;
    line-height: 4.8rem;
    /* border:none; */
    border: solid 1px #aaaaaa;
}
.searchBtn{
    text-align: center;
    cursor: pointer;
    margin: 0 9px;
    width: 16rem;
    height: 5rem;
    font-size: 1.8rem;
    background-color: #c40001;
    color: #FFFFFF;
    line-height: 5rem;
    border:none;
}
.info{
    font-size: 1.6rem;
    color: #333333;
    text-align: center;
    background-color: #f5f5f5;
    height: 4.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 8.7rem; */
}
.xing{
    font-size: 24px;
    color: #ff0000;
    margin-right: 8px;
    position: absolute;
    left: -18rem;
    top: 1.6rem;
}
>>>.el-dialog__header{
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #333333;
    background-color: #ececec;
    text-transform: uppercase;
    padding:0;
    border-top: solid 4px #709bc2;
}
>>>.el-dialog__body{
    padding:1rem 5rem 3rem 5rem;
}
.dialogInfo{
    text-indent: 3.2rem;
    font-size: 1.6rem;
    color: #333333;
    line-height: 3.2rem;
    /* padding: 0 45px 0 33px; */
    
    margin-bottom: 6px;
    text-align: left;
}

.dialogform >>>.el-input-group__prepend{
    border-radius: 0;
    border:none;
   
    font-size: 1.8rem;
    color: #FFFFFF;
    background-color: #2071b5;
    width: 12rem;
    height: 5rem;
   
    padding:0;
}
.dialogform >>>.el-input__inner{
    background-color: #fff;
    border: solid 1px #dddddd;
    border-radius: 0;
    color:#000;
    font-size:1.6rem;
}
.code{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.codeBtn{
    width: 20rem;
    cursor: pointer;
    font-size: 1.8rem;
    color: #FFFFFF;
    background-color: #2071b5;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border:none;
    margin-left:1rem;
}
.submitBtn{
    width: 23rem;
    height: 5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4.4rem auto 0.9rem;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #c40000;
}
</style>
<style>

@media only screen and (max-width: 767px){
    .pageOne[data-v-c3a4c812] {
        background-color: #fff;
        padding: 1.5rem 0 0 0;
        margin-top: 1rem;
    }
    h3 {
        font-size: 1.6rem !important;
        color: #37496e;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
    }
    .subTitle{
        margin: 10px auto 0 !important;
        font-size: 1.2rem !important;
    }
    .form{
        padding-top:1rem !important;
    }
    .el-form-item__content{
        
        margin-left: 0px !important;
        margin-top:5rem;
    }
    .el-form-item__label {
        font-size: 1.6rem !important;
        color: #FFFFFF;
        background-color: #2071b5;
        height: 4rem  !important;
        text-align: center;
        border-radius: 8px;
        line-height: 4rem  !important;
        text-align: center;
        padding: 0;
    }
    .el-checkbox{
        width:100% !important;
        text-align: left;
    }
    .btnGroup{
        margin-top:0rem !important;
    }
    .searchBtn{
        margin-top:1rem !important;
    }
    .el-radio-group{
        text-align: left;
    }
    .footer .name{
        margin-top:0 !important;
    }
    .el-dialog{
        width:98% !important;
    }
    .el-dialog__body{
        padding:1rem !important;
    }
    .dialogform .el-input-group__prepend{
        font-size: 1.6rem !important;
        width: 10rem !important;
    }
    .setting{
        display:none;
    }
    .btnGroup{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .searchBtn{
        margin-top:0 !important;
    }
    .reset {
        text-align: center;
        cursor: pointer;
        margin: 0 9px;
        width: 10rem;
        height: 3rem;
        font-size: 1.4rem;
        color: #333333;
        background-color: #f5f5f5;
        cursor: pointer;
        border: solid 1px #aaaaaa;
        line-height: 3rem;
        /* border: none; */
        border: solid 1px #aaaaaa;
    }
    .searchBtn {
        text-align: center;
        cursor: pointer;
        margin: 0 9px;
        width: 10rem;
        height: 3rem;
        font-size: 1.4rem;
        background-color: #c40001;
        color: #FFFFFF;
        line-height: 3rem;
        border: none;
    }
   
}
@media only screen and (min-width: 768px){
    .el-form-item__content{
        text-align: left;
        margin-left: 166px !important;
        line-height: 2.5rem;
    }
}
@media only screen and (min-width: 992px){
    .el-form-item__content{
        text-align: left;
        margin-left: 166px !important;
        line-height: 2.5rem;
    }
}
</style>