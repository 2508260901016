<template>
  <div id="app">
    <div>
      <el-row style="background-color: #eef1f5;">
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
          <div class="title">
            <span>欢迎访问商标注册查询系统中心</span><span>知识产权信息服务平台</span>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        
      </el-row>
      <!-- -->
      <el-row>
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
          <div class="logo">
            <img src="./assets/img_logo.png" alt="">
            <div class="titleLogo">
                <div class="topic">商标注册查询备案系统</div>
                <div class="subTopic">Trademark Registration Inquiry and Recording System</div>
            </div>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        
      </el-row>
      <el-row style="background-color: #709bc2;">
        <el-col :xs="24" :sm="24" :md="1" :lg="1" :xl="1"></el-col>
        <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22">
          <div class="tabs">
            <div @click="setIndex(index)" :class="activeIndex==index?'tabItem active':'tabItem'" v-for="(item,index) in tabList" :key="index">{{item}}</div>
        </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="1" :lg="1" :xl="1"></el-col>
        
      </el-row>
     
     
    </div>
    
     <div class="content">
      <el-row>
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22"><pageOne v-if="activeIndex==0"/><pageTwo v-if="activeIndex==1"/></el-col>
        <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        
      </el-row>
      
     </div>
     <div class="footer">
        <div style="width:100%;">
          <el-row>
            <el-col :xs="24" :sm="24" :md="3" :lg="6" :xl="6"></el-col>
            <el-col :xs="24" :sm="24" :md="18" :lg="12" :xl="12">
              <el-row>
                <el-col :span="12"><span style="cursor: pointer;" @click="setIndex(0)">网上快捷申请</span></el-col>
                <el-col :span="12"><span style="cursor: pointer;" @click="setIndex(1)">商标查询</span></el-col>
                <!-- <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4"><span style="cursor: pointer;" @click="setIndex(1)">国际商标申请</span></el-col>
                <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4"><span style="cursor: pointer;" @click="setIndex(1)">注册流程图</span></el-col>
                <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4"><span style="cursor: pointer;" @click="setIndex(1)">商标申请指南</span></el-col>
                <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4"><span style="cursor: pointer;" @click="setIndex(1)">申请书式文件</span></el-col>
                 -->
              </el-row>
            </el-col>
            <el-col :xs="24" :sm="24" :md="3" :lg="6" :xl="6"></el-col>
            
          </el-row>
         
        </div>
     </div>
    
  </div>
</template>

<script>
import pageOne from './components/pageOne.vue'
import pageTwo from './components/pageTwo.vue'
export default {
  name: 'App',
  components:{
    pageOne,pageTwo
  },
  data(){
    return{
      // tabList:['网上快捷申请','商标查询','国际商标申请','商标增类申请','注册流程图','商标申请指南'],
      tabList:['网上快捷申请','商标查询'],
      activeIndex:0,
    }
  },
  methods:{
    setIndex(index){
      console.log(index)
      this.activeIndex = index;
      if(index==0){
        window.location.href='https://bdjhy.jzynkj.com/zhu'
      }
      if(index==1){
        window.location.href='https://bdjhy.jzynkj.com/cha'
      }
    },
    // initInnerHandle(){
    //   var agl = document.createElement('script');
    //   agl.type = 'text/javascript';
    //   agl.async = true;
    //   agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
    //   var s = document.getElementsByTagName('script')[0];
    //   s.parentNode.insertBefore(agl, s);
    //   window._agl && window._agl.push(['track', ['success', {t: 3}]])
    // },
    // initHandle(){
     
    //   window._agl = window._agl || [];
    //   _agl.push(
    //     ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
    //   );
    //   this.initInnerHandle()
     

    // }
  },
  created(){
    // this.initHandle();
    console.log(window.location.href)
    if(window.location.href.indexOf('/cha')!=-1){
      this.activeIndex=1
    }
    if(window.location.href.indexOf('/zhu')!=-1){
     this.activeIndex=0
    }
  }
}
</script>

<style>
html,body{
  padding:0;
  font-size: 62.5%;
  margin:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin:0;
  padding:0;
 
}
.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.6rem;
  background-color: #eef1f5;
  padding:0 2rem;
  font-size:1.6rem;
}
.logo{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding:1rem 2rem;
}
.titleLogo{
  margin-left: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.titleLogo .topic{
  font-size: 3.2rem;
    color: #0151a4;
    font-weight: 700;
    line-height: 4.2rem;
}
.titleLogo .subTopic{
  font-size: 1.4rem;
    color: #999999;
    line-height: 2.2rem;
}
.tabs{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #709bc2;
    height: 4.2rem;
    padding:0rem 2rem;
}
.tabs .tabItem{
  flex:1;
  height:104%;
  /* line-height: 4.2rem; */
  text-align: center;
  cursor: pointer;
    font-size: 1.6rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active{
  background-color: #ff9724;
}
.content{
  /* flex:1; */
  background-color: #d5dbe7;
}
.el-col{
  border:1px solid transparent;
}
.footer{
  background-image: url(./assets/bg_foot.png);
  /* background-size: ; */
  height: 7.8rem;
  font-size: 1.6rem;
  font-weight: bold;
  background-size: cover;
  border-top: solid 1px #48769f;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  position: relative;
  color:#fff;
}
.footer .name{
 
    font-size: 14px;
    color: #ffffff;
    opacity: 0.4;
   
    text-align: center;
    margin-top:1rem;
}
@media only screen and (max-width: 767px){
  .title{
    font-size: 1.2rem;
    padding:0;
  }
  .logo{
    padding:1rem 0;
  }
  .logo img{
    width:6rem;
    height:6rem;
  }
  .titleLogo .topic{
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .titleLogo .subTopic{
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.4rem;
  }
   .tabs{
        padding: 0;
        
    }
    .tabs .tabItem{
      font-size:1.2rem;
      height:113%;
    }
}
@media only screen and (min-width: 768px){
 
}
@media only screen and (min-width: 992px){

}
</style>
